.dashboard-backdrop {
    background: url("../../assets/images/apidocs.jpeg");
    min-height: 20rem;
    background-size: cover;
    background-position: center;
    box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);

    > .dashboard-backdrop-fill {
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9) 80%);
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 4rem 4rem 2.5rem;
        height: 100%;
        width: 100%;

        > .inner {
            color: #fff;
            text-shadow: 0 2px 5px #000;

            h1 {
                font-size: 48px;
                margin-bottom: 1rem;
            }

            p {
                font-size: 16px;
                opacity: 0.6;

                span {
                    &:not(:first-child) {
                        margin-left: 5rem;
                    }
                }
            }
        }
    }
}
